import React, { useState, useEffect } from "react"
import moment from "moment"
import { PayPalButton } from "react-paypal-button-v2"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Container, Row, Col, Form, Card, Modal, Button } from "react-bootstrap"
import Loader from "../components/Loader"
import Message from "../components/Message"
import { tripDetails } from "../actions/productActions"
import TripsHotels from "../components/TripsHotels"
import axios from "axios"
var CurrencyFormat = require("react-currency-format")

const ProductScreenTrips = ({ location, match }) => {
  const [name, setName] = useState("")
  const [pickupDate, setPickupDate] = useState("0000-00-00")
  const [pickupTime, setPickupTime] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [notes, setNotes] = useState("")
  const [hotelId, sethotelId] = useState(0)
  const [hotelRates, setHotelRates] = useState([])
  const [hotelName, sethotelName] = useState("")
  const [showPickupHotel, setShowPickupHotel] = useState(false)

  const [numOfPersons, setNumOfPersons] = useState(0)

  const server = "https://transportation-backend-test.link"

  const {
    tripPrice,
    transportationNameSelected,
    kindOfTrip,
    serviceId,
    transportationIdSelected,
    tripId,
    tripProject,
  } = location.state

  const [show, setShow] = useState(false)
  const [showPaypalComplete, setShowPaypalComplete] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleClosePaypalComplete = () => setShowPaypalComplete(false)
  const handleShowPaypalComplete = () => setShowPaypalComplete(true)

  const handleClosePickupHotel = () => setShowPickupHotel(false)
  const handleShowPickupHotel = () => setShowPickupHotel(true)

  const dispatch = useDispatch()

  const tripDetailsInfo = useSelector((state) => state.tripDetailsInfo)
  const { loading, error, trip } = tripDetailsInfo

  let tripName = trip.name

  const getLimitNumOfPassengers = (transportationNameSelected, productName) => {
    var numberOfPassengers = []
    if (productName === "Shuttle") {
      numberOfPassengers = transportationNameSelected.split(" ")
      return numberOfPassengers[1]
    } else {
      numberOfPassengers = transportationNameSelected.split("-")
      if (numberOfPassengers.length === 1) {
        numberOfPassengers = transportationNameSelected.split(" ")
        return numberOfPassengers[1]
      } else {
        return numberOfPassengers[1]
      }
    }
  }

  const limitNumOfPassengers = getLimitNumOfPassengers(
    transportationNameSelected,
    tripName
  )

  function toPayHandler(event) {
    event.preventDefault()

    if (hotelName === " " || hotelName === "") {
      handleShowPickupHotel()
      return
    }

    handleShow()
  }

  const fetchHotelRates = async () => {
    const { data } = await axios.get(`${server}/hotels-trips-paying`)

    data.unshift({
      id: 0,
      Name: " ",
    })

    setHotelRates(data)
  }

  useEffect(() => {
    dispatch(tripDetails(match.params.id, match.params.tripId))
    fetchHotelRates()
  }, [dispatch, match])

  return (
    <>
      <Link
        className='btn btn-light my-3'
        to={`/transportations-trips/${tripId}`}
      >
        Go Back
      </Link>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <Container fluid>
          <Row className='justify-content-md-center'>
            <Col xs={12} sm={4} md={4} lg={4}>
              <Card className='text-center'>
                <Card.Img
                  variant='top'
                  src={trip.image}
                  alt={transportationNameSelected}
                />
                <Card.Body>
                  <Card.Title>{transportationNameSelected} </Card.Title>
                  <Card.Title>{tripProject}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col xs={12} sm={6} md={6} lg={6}>
              <TripsHotels
                hotelRates={hotelRates}
                value={hotelName}
                onChange={(event) => {
                  sethotelName(event.target.value)
                  sethotelId(
                    event.target[event.target.selectedIndex].getAttribute(
                      "data-hotelid"
                    )
                  )
                }}
              />
            </Col>
          </Row>

          <Row className='text-center'>
            <Col sm={12}>
              <Form onSubmit={toPayHandler}>
                <Form.Row className='justify-content-center m-2'>
                  <Col lg={2}>
                    <Form.Label>Pick-Up Date:</Form.Label>
                    <Form.Control
                      name='date'
                      type='date'
                      value={pickupDate}
                      onChange={(e) => setPickupDate(e.target.value)}
                      required
                      min={moment().format("YYYY-MM-DD")}
                    />
                  </Col>
                </Form.Row>
                <Form.Row className='justify-content-center m-2'>
                  <Col lg={2}>
                    <Form.Label>Pick-Up Time:</Form.Label>
                    <Form.Control
                      name='time'
                      type='time'
                      value={pickupTime}
                      onChange={(e) => setPickupTime(e.target.value)}
                      required
                      min='08:00'
                      max='15:00'
                    />
                  </Col>
                </Form.Row>
                <Form.Row className='justify-content-center m-2'>
                  <Col lg={2}>
                    <Form.Label>Number Of Passengers:</Form.Label>
                    <Form.Control
                      type='number'
                      value={numOfPersons}
                      onChange={(e) => setNumOfPersons(e.target.value)}
                      min='1'
                      max={limitNumOfPassengers}
                      required
                    />
                  </Col>
                </Form.Row>
                <Form.Row className='justify-content-center m-2'>
                  <Col lg={4} className='m-1'>
                    <Form.Control
                      type='text'
                      size='30'
                      placeholder='Name'
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Col>
                  <Col lg={4} className='m-1'>
                    <Form.Control
                      type='text'
                      size='30'
                      placeholder='Last Name'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </Col>
                  <Col lg={4} className='m-1'>
                    <Form.Control
                      type='email'
                      placeholder='Email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Col>
                  <Col lg={4} className='m-1'>
                    <Form.Control
                      as='textarea'
                      rows='3'
                      name='notes'
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder='Notes'
                    />
                  </Col>
                </Form.Row>
                <Form.Row className='justify-content-center m-2'>
                  <Col lg={3} className='m-1'>
                    <Form.Label>Total:</Form.Label>
                    <CurrencyFormat
                      value={tripPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </Col>
                </Form.Row>
                <Form.Row className='justify-content-center m-2'>
                  <Button type='submit' variant='outline-dark' size='md'>
                    Pay
                  </Button>
                </Form.Row>
              </Form>
            </Col>
          </Row>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton></Modal.Header>
            <PayPalButton
              amount={tripPrice}
              onSuccess={(details, data) => {
                let today = moment().format("YYYY-MM-DD")
                let arrivalNumOfPassengers = 0
                let departureNumOfPassengers = 0
                let IdArrivalService = null
                let IdDepartureService = null
                let IdArrivalTransportation = null
                let IdDepartureTransportation = null
                let IdTrip = null
                var time = moment().format("hh:mm:ss")
                let arrivalHotelName = ""
                let departureHotelName = ""
                let arrivalTransportation = ""
                let departureTransportation = ""
                let arrivalAirline = null
                let departureAirline = null
                let flight = 0
                let shuttleHour = ""

                if (kindOfTrip === "Departure") {
                  IdTrip = 2
                  IdDepartureService = serviceId
                  departureNumOfPassengers = numOfPersons
                  IdDepartureTransportation = transportationIdSelected
                  departureHotelName = hotelName
                  departureTransportation = transportationNameSelected
                }

                if (kindOfTrip === "Round") {
                  IdTrip = 3
                  IdArrivalService = serviceId
                  arrivalNumOfPassengers = numOfPersons
                  IdArrivalTransportation = transportationIdSelected
                  arrivalHotelName = hotelName
                  arrivalTransportation = transportationNameSelected

                  IdDepartureService = serviceId
                  departureNumOfPassengers = numOfPersons
                  IdDepartureTransportation = transportationIdSelected
                  departureHotelName = hotelName
                  departureTransportation = transportationNameSelected
                }

                setPickupDate(moment(pickupDate).format("YYYY-MM-DD"))

                handleShowPaypalComplete()

                return fetch(`${server}/paypal-transaction-complete-trip`, {
                  method: "POST",
                  headers: {
                    "content-type": "application/json",
                  },
                  body: JSON.stringify({
                    orderID: data.orderID,
                    FechaPago: today,
                    FechaSalida: pickupDate,
                    FechaLlegada: pickupDate,
                    HoraLlegada: pickupTime,
                    HoraSalida: pickupTime,
                    FechaControl: today,
                    Usuario: "web",
                    Email: email,
                    Name: name,
                    LastName: lastName,
                    IdArrivalAirline: arrivalAirline,
                    IdDepartureAirline: departureAirline,
                    PersonasLlegada: arrivalNumOfPassengers,
                    PersonasSalida: departureNumOfPassengers,
                    NumeroLlegada: flight,
                    NumeroSalida: flight,
                    IdHotel: hotelId,
                    IdTrip: IdTrip,
                    IdArrivalService: IdArrivalService,
                    IdDepartureService: IdDepartureService,
                    IdArrivalTransportation: IdArrivalTransportation,
                    IdDepartureTransportation: IdDepartureTransportation,
                    ShuttleDepartureTime: shuttleHour,
                    ImporteTotalDlls: tripPrice,
                    Notas: notes,
                    Hora: time,
                    PaypalAmount: details.purchase_units[0].amount.value,
                    PaypalCurrency_code:
                      details.purchase_units[0].amount.currency_code,
                    PaypalEmail_address: details.payer.email_address,
                    PaypalName: details.payer.name.given_name,
                    PaypalLastName: details.payer.name.surname,
                    PaypalPayerId: details.payer.payer_id,
                    PaypalStatus: details.status,
                    PaypalId: details.id,
                    transportationNameSelected: transportationNameSelected,
                    kindOfTrip: kindOfTrip,
                    hotelName: hotelName,
                    arrivalHotelName: arrivalHotelName,
                    departureHotelName: departureHotelName,
                    arrivalTransportation: arrivalTransportation,
                    departureTransportation: departureTransportation,
                    project: tripId,
                    tripProject: `${tripProject} (${kindOfTrip} Trip)`,
                  }),
                })
              }}
              options={{
                clientId:
                  "AYZm0tquYgY-AShbUdA0qsBvLsMINEHXUAkxmlRk4ij1zXWDZ5xdRfebGWUmjePKGjA7FvzdFb2KbDyA",
              }}
            />
          </Modal>

          <Modal
            backdrop='static'
            keyboard={false}
            show={showPaypalComplete}
            onHide={handleClosePaypalComplete}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Transaction Complete, Please Check Your Email...
              </Modal.Title>
            </Modal.Header>

            <Modal.Footer>
              <Button variant='primary' onClick={handleClosePaypalComplete}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showPickupHotel} onHide={handleClosePickupHotel}>
            <Modal.Header closeButton>
              <Modal.Title>Please Select The Pick-Up Hotel</Modal.Title>
            </Modal.Header>
          </Modal>
        </Container>
      )}
    </>
  )
}

export default ProductScreenTrips
