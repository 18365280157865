import {createStore, combineReducers,applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools } from 'redux-devtools-extension'
import {productListReducer,productDetailsReducer,hotelListReducer,productListReducerProjects,projectListInfoReducer,projectDetailsInfoReducer,weddingDetailsInfoReducer,weddingDetailsReducer,groupDetailsInfoReducer,groupDetailsReducer,tourDetailsInfoReducer,tripDetailsInfoReducer,hourlyDetailsInfoReducer} from './reducers/productReducers'
import {payReducer} from './reducers/payReducers'
import {hotelRatesListReducer} from './reducers/rateReducers'

const reducer=combineReducers({
    productList:productListReducer,
    productDetails:productDetailsReducer,
    projectDetailsInfo:projectDetailsInfoReducer,
    weddingDetailsInfo:weddingDetailsInfoReducer,
    groupDetailsInfo:groupDetailsInfoReducer,    
    tourDetailsInfo:tourDetailsInfoReducer,    
    tripDetailsInfo:tripDetailsInfoReducer,    
    hourlyDetailsInfo:hourlyDetailsInfoReducer,    
    hotelList:hotelListReducer,
    pay:payReducer,
    hotelRatesList:hotelRatesListReducer,
    productListProjects:productListReducerProjects,
    projectListInfo:projectListInfoReducer,
})

//const buyDataFromStorage=localStorage.getItem('buydata')?JSON.parse(localStorage.getItem('buydata')):[]

// const initialState={
//     total:{buydata:buyDataFromStorage}
// }

const middleware=[thunk]

//const store=createStore(reducer,initialState,composeWithDevTools (applyMiddleware(...middleware)))
const store=createStore(reducer,composeWithDevTools (applyMiddleware(...middleware)))

export default store
